export enum ClientCountries {
    AUSTRALIA = 'aus',
    UNITED_STATES = 'us'
}

export enum TwilioCountriesAbbr {
    AUSTRALIA = 'AS',
    CANADA = 'CA',
    INDIA = 'IN',
    NEW_ZEALAND = 'NZ',
    UNITED_KINGDOM = 'UK',
    UNITED_STATES = 'US'
}
