import { TwilioCountriesAbbr } from '@/core/country-constants';

/**
 * Handles utility functionality for client.
 */
export class ClientUtils {
    /**
     * Get the business name tooltip message.
     *
     * @param country
     */
    public getBusinessNameTooltip(country: TwilioCountriesAbbr | string): string {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return 'This must exactly match the business name on the business formation documents submitted to your Secretary of State in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.CANADA:
                return 'This must exactly match the business name on the business formation documents submitted to Corporations Canada in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.AUSTRALIA:
                return 'This must exactly match the business name on the business formation documents submitted to the Australian Securities & Investments Commission in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return 'This must exactly match the business name on the business formation documents submitted to Companies House in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.NEW_ZEALAND:
                return 'This must exactly match the business name on the business formation documents submitted to the Companies Office in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.INDIA:
            default:
                return 'This must match exactly the business name on your formation documents to enable Texting and/or Call Recording features.';
        }
    }

    /**
     * Get the business registration label.
     *
     * @param country
     */
    public getBusinessRegistrationLabel(country: TwilioCountriesAbbr | string): string {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return 'Employer Identification Number (EIN)';
            case TwilioCountriesAbbr.CANADA:
                return 'Business Number (BN)';
            case TwilioCountriesAbbr.AUSTRALIA:
                return 'Australian Business Number (ABN)';
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return 'Unique Taxpayer Reference (UTR)';
            case TwilioCountriesAbbr.NEW_ZEALAND:
                return 'New Zealand Business Number (NZBN)';
            case TwilioCountriesAbbr.INDIA:
                return 'Permanent Account Number (PAN)';
            default:
                return 'Business Registration Number';
        }
    }

    /**
     * Get the business registration validation rules.
     *
     * @param country
     */
    public getBusinessRegistrationValidationRules(country: TwilioCountriesAbbr | string) {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return [(value: string) => /^[0-9]{2}-[0-9]{7}$/.test(value) || 'Your EIN should be formatted XX-XXXXXXX'];
            case TwilioCountriesAbbr.CANADA:
                return [(value: string) => /^[0-9]{9}$/.test(value) || 'Your BN should be 9 digits long'];
            case TwilioCountriesAbbr.AUSTRALIA:
                return [
                    (value: string) =>
                        /^[0-9]{2} [0-9]{3} [0-9]{3} [0-9]{3}$/.test(value) || 'Your ABN should be formatted XX XXX XXX XXX'
                ];
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return [
                    (value: string) => {
                        // Normalize input: remove spaces, special characters, and ensure lowercase for "k"
                        const normalized = value.replace(/\s+/g, '').toLowerCase();

                        // Check for valid UTR format
                        const isValidUTR =
                            /^k?\d{10}k?$/.test(normalized) || // 10 digits, optionally starting or ending with "k"
                            /^k?\d{13}k?$/.test(normalized); // 13 digits, optionally starting or ending with "k"

                        return (
                            isValidUTR ||
                            'Your UTR should be 10 or 13 digits, with or without spaces, and may start or end with "K".'
                        );
                    }
                ];
            case TwilioCountriesAbbr.NEW_ZEALAND:
                return [(value: string) => /^[0-9]{13}$/.test(value) || 'Your NZBN should be 13 digits long'];
            case TwilioCountriesAbbr.INDIA:
            default:
                return [(value: string) => !!value || 'This field is required'];
        }
    };

    /**
     * Get the business registration tooltip message.
     *
     * @param country
     */
    public getBusinessRegistrationTooltip(country: TwilioCountriesAbbr | string): string {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return 'Required to enable the Texting and/or Call Recording features. Note: Include the dash.';
            case TwilioCountriesAbbr.AUSTRALIA:
                return 'Required to enable the Texting and/or Call Recording features. Note: Include the spaces.';
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return 'Required to enable the Texting and/or Call Recording features. Note: Include spaces if your number has them.';
            case TwilioCountriesAbbr.CANADA:
            case TwilioCountriesAbbr.NEW_ZEALAND:
            case TwilioCountriesAbbr.INDIA:
            default:
                return 'Required to enable the Texting and/or Call Recording features.';
        }
    }
}
